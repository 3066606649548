<template>
  <v-row class="fill-height text-center justify-center">
    <v-col cols="12">
      <h1>{{ $t('reportError.car.quickstart') }}</h1>
    </v-col>
    <v-spacer />
    <v-col
      v-for="item in navigationElements"
      v-show="item.available"
      :key="item.title"
      sm="4"
    >
      <custom-card :creation-data="item" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
const CustomCard = () => import('@/components/GlobalComponents/CustomCard')

export default {
  name: 'QuickStartCar',
  components: {
    CustomCard
  },
  computed: {
    ...mapGetters('User', ['getUserData']),
    navigationElements () {
      return [
        {
          available: this.getUserData.role === 'OPERATOR' || this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'ErrorReportCar',
          icon: 'mdi-alert-octagon',
          title: 'Nahlásenie chyby',
          description: 'Tu môžete nahlásiť chybu vozidla'
        },
        {
          available: this.getUserData.role === 'MAINTAINER' || this.getUserData.role === 'ADMIN',
          routeName: 'ErrorDashboardCar',
          icon: 'mdi-bulletin-board',
          title: 'Nahlásené poruchy',
          description: 'Dashboard nahlásených chýb vozidiel'
        },
        {
          available: this.getUserData.role === 'MAINTAINER' || this.getUserData.role === 'ADMIN',
          routeName: 'TaskDashboardCar',
          icon: 'mdi-briefcase',
          title: 'História porúch',
          description: 'Dashboarduloh ktoré sú priradené'
        },
        {
          available: this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'ErrorAssignCar',
          icon: 'mdi-account-hard-hat',
          title: 'Priradiť chybu',
          description: 'Môžete priradiť chybu vozidla'
        },
        {
          available: this.getUserData.role === 'ADMIN' || this.getUserData.role === 'HEAD_MAINTAINER',
          routeName: 'PerformancePageCar',
          icon: 'mdi-speedometer',
          title: 'KPI vozidiel',
          description: 'Stránka KPI vozidiel'
        }
      ]
    }
  }
}
</script>
